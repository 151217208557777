import DashboardCardLoading from "./shared/DashboardCardLoading";
import React from "react";
import {
  Chip,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { IconActivity } from "@tabler/icons";
import DashboardCard from "./shared/DashboardCard";
import { formatDate } from "../../services/date-uitils";
import {
  capitalizeFirstLetter,
  formatPriceNumber
} from "../../services/string-utils";

const ActivityDetails = ({ activities, user }) => {
  if (!activities) {
    return <DashboardCardLoading />;
  }
  const lastPaymentExists = activities.payment !== null;
  const lastRequestExists = activities.loan !== null;
  const pendingReup = user?.pendingReUp === true;

  const lastPayment = activities.payment || {};
  const lastRequest = activities.loan || {};

  if (!lastPaymentExists && !lastRequestExists) {
    return null;
  }

  const lastPaymentDateStr = formatDate(lastPayment.date);
  const lastRequestDateStr = formatDate(lastRequest.date);
  const lastPaymentStatusStr = capitalizeFirstLetter(lastPayment.status);
  const lastRequestStatusStr = capitalizeFirstLetter(lastRequest.status);
  const lastPaymentStatusColor = lastPayment.status === "ERROR" || lastPayment.status === "DENIED" ? "error"
    : (lastPayment.status === "ACCEPTED" ? "success"
      : (lastPayment.status === "PENDING" ? "warning"
        : "primary"));
  const lastRequestStatusColor = lastRequest.status === "ERROR" || lastRequest.status === "DENIED" ? "error"
    : (lastRequest.status === "ACCEPTED" ? "success"
      : (lastRequest.status === "PENDING" ? "warning"
        : "primary"));

  return (
    <DashboardCard title='Activity'
                   action={
                     <Fab color='secondary' size='medium'
                          sx={{ color: "#ffffff", cursor: "default" }}>
                       <IconActivity width={24} />
                     </Fab>
                   }
    >
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant='h6'>
              </Typography>
            </TableCell>
            <TableCell align='center'>
              <Typography variant='h6'>
                Date
              </Typography>
            </TableCell>
            <TableCell align='center'>
              <Typography variant='h6'>
                Amount
              </Typography>
            </TableCell>
            <TableCell align='center'>
              <Typography variant='h6'>
                Status
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lastPaymentExists && <TableRow>
            <TableCell>
              <Typography variant='h6'>
                Manual payment processed
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h6'>
                {lastPaymentDateStr}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h6'>
                ${formatPriceNumber(lastPayment.amount)}
              </Typography>
            </TableCell>
            <TableCell align='right'>
              <Chip
                sx={{
                  px: "4px",
                  color: "#fff"
                }}
                size='small'
                label={lastPaymentStatusStr}
                color={lastPaymentStatusColor}
              ></Chip>
            </TableCell>
          </TableRow>}
          {lastRequestExists && <TableRow>
            <TableCell>
              <Typography variant='h6' padding={0}>
                {pendingReup ? "Additional Loan Application" : "Loan Application"}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h6' align='center'>
                {lastRequestDateStr}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant='h6' align='center'>
                ${formatPriceNumber(lastRequest.amount)}
              </Typography>
            </TableCell>
            <TableCell align='center'>
              <Chip
                sx={{
                  px: "4px",
                  color: "#fff"
                }}
                size='small'
                label={lastRequestStatusStr}
                color={lastRequestStatusColor}
              ></Chip>
            </TableCell>
          </TableRow>}
        </TableBody>
      </Table>
    </DashboardCard>
  );
};

export default ActivityDetails;
