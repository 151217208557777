import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField
} from "@mui/material";
import { useDispatch } from "react-redux";
import PageContainer from "../../shared/components/container/PageContainer";
import ReCAPTCHA from "react-google-recaptcha";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Logo from "../../shared/components/logo/Logo";
import { login } from "../../actions/authActions";
import { LoadingButton } from "@mui/lab";
import Cookies from "js-cookie";

const Login = () => {
  const dispatch = useDispatch();
  const recaptchaRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    ssn: "",
    token: ""
  });
  const [showSSN, setShowSSN] = useState(false);
  const toggleSSNVisibility = (ele) => {
    setShowSSN((prevShowSSN) => !prevShowSSN);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    recaptchaRef.current.execute();
  };

  const handleRecaptchaVerify = async (response) => {
    const form = { ...formData, token: response };
    setFormData(form);
    await dispatch(login(form));
    setLoading(false);
  };
  const handleRecaptchaExpire = () => {
    setFormData({ ...formData, token: "" });
  };

  useEffect(() => {
    const email = Cookies.get("fcl-landing-login-email");
    const ssn = Cookies.get("fcl-landing-login-ssn");
    if (email && ssn) {
      const hostname = window.location.hostname;
      const parts = hostname.split(".");
      let domain = hostname;
      if (parts.length > 2) {
        domain = parts.slice(-2).join(".");
      }
      document.cookie = `fcl-landing-login-email=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.${domain}`;
      document.cookie = `fcl-landing-login-ssn=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.${domain}`;
      setFormData((prev) =>
        ({ ...prev, email: email, ssn: ssn }));
      setLoading(true);
      recaptchaRef.current.execute();
    }
  }, []);

  return (
    <>
      <PageContainer title='Login'>
        <Box
          sx={{
            position: "relative",
            "&:before": {
              content: "\"\"",
              background: "radial-gradient(#d2f1df, #d3d7fa, #bad8f4)",
              backgroundSize: "400% 400%",
              animation: "gradient 15s ease infinite",
              position: "absolute",
              height: "100%",
              width: "100%",
              opacity: "0.3"
            }
          }}
        >
          <Grid container spacing={0} justifyContent='center'
                sx={{ height: "100vh" }}>
            <Grid
              item
              xs={12}
              sm={12}
              lg={4}
              xl={3}
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <Card elevation={9}
                    sx={{ p: 4, zIndex: 1, width: "100%", maxWidth: "500px" }}>
                <form onSubmit={handleSubmit}>
                  <Box display='flex' alignItems='center'
                       justifyContent='center'>
                    <Logo />
                  </Box>
                  <Stack spacing={3} my={3}>
                    <TextField id='email'
                               label='Email'
                               variant='outlined'
                               type='email'
                               name='email'
                               disabled={isLoading}
                               value={formData.email}
                               onChange={handleInputChange}
                               required
                               fullWidth />
                    <TextField id='ssn'
                               label='Last four of SSN'
                               type={showSSN ? "text" : "password"}
                               variant='outlined'
                               fullWidth
                               required
                               name='ssn'
                               value={formData.ssn}
                               onChange={handleInputChange}
                               disabled={isLoading}
                               inputProps={{ maxLength: 4, pattern: "[0-9]*" }}
                               InputProps={{
                                 endAdornment: (
                                   <InputAdornment position='end'>
                                     <IconButton
                                       onClick={toggleSSNVisibility}
                                       onMouseDown={(e) => e.preventDefault()}
                                       edge='end'
                                     >
                                       {showSSN ? <VisibilityOff /> :
                                         <Visibility />}
                                     </IconButton>
                                   </InputAdornment>
                                 )
                               }}
                    />
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_GA_CAPTCHA_KEY}
                      ref={recaptchaRef}
                      size='invisible'
                      onChange={handleRecaptchaVerify}
                      onExpired={handleRecaptchaExpire}
                    />
                  </Stack>
                  <Box>
                    <LoadingButton
                      loading={isLoading}
                      color='primary'
                      variant='contained'
                      size='large'
                      fullWidth
                      type='submit'
                    >
                      Sign In
                    </LoadingButton>
                  </Box>
                </form>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </PageContainer>
    </>
  );
};

export default Login;
