import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import { ArrowRightAlt } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const DashboardPayCard = ({ icon, title, description, link, onClick }) => {

  const navigate = useNavigate();

  const onNavigate = () => {
    navigate(link);
  };

  return (
    <CardActionArea style={{ maxWidth: "275px" }}
                    onClick={onClick ?? onNavigate}>
      <Card>
        <CardContent>
          <Stack display='flex'
                 spacing={2}
                 direction='column'
                 justifyContent='start'
                 alignItems='center'>
            <Avatar variant='rounded'
                    sx={{
                      width: 58,
                      height: 58,
                      bgcolor: (theme) => theme.palette.info.main
                    }}>
              {icon}
            </Avatar>
            <Typography variant='h5' align='center'>{title}</Typography>
            <Typography variant='h7' align='center'
                        color='textSecondary'>{description}</Typography>
            <ArrowRightAlt />
          </Stack>
        </CardContent>
      </Card>
    </CardActionArea>
  );
};

export default DashboardPayCard;
