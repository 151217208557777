import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import states from "../../assets/states.json";
import { loadApplicationData } from "../../actions/loanActions";
import { useDispatch } from "react-redux";
import StepLoading from "../../shared/components/StepLoading";
import apiService from "../../services/apiService";
import { enqueueSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { formatPriceNumber } from "../../services/string-utils";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ApplyNewLoanStep2 = ({
                             isLoadingData,
                             amountToBorrow,
                             formData,
                             onComplete,
                             onBack
                           }) => {

  const dispatch = useDispatch();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    dispatch(loadApplicationData());
  }, [dispatch]);

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = React.useState({
    loanInformation: {
      amount: 0.0
    },
    personalInformation: {
      ssn: "",
      firstName: "",
      lastName: "",
      email: "",
      dateOfBirth: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      homePhone: "",
      cellPhone: ""
    },
    bankInformation: {
      bankName: "",
      abaNumber: "",
      accountNumber: "",
      accountType: ""
    },
    workInformation: {
      employmentType: "FullTime",
      filedForBankruptcy: false,
      payCheckForm: "DirectDeposit",
      payFrequency: "BiWeekly",
      monthlyPayAmount: 0.0,
      lastPayDate: moment(),
      nextPayDate: moment(),
      workPhone: "",
      jobTitle: "",
      employerName: ""
    }
  });
  const [checked, setChecked] = useState(false);

  React.useEffect(() => {
    if (!formData) {
      return;
    }
    const currentDate = moment();
    let lastPayDate = moment(formData.workInformation.lastPayDate);
    let nextPayDate = moment(formData.workInformation.nextPayDate);
    if (nextPayDate <= currentDate) {
      lastPayDate = null;
      nextPayDate = null;
    }
    const data = {
      ...formData,
      ...{ loanInformation: { amount: amountToBorrow } },
      workInformation: {
        ...formData.workInformation,
        lastPayDate: lastPayDate,
        nextPayDate: nextPayDate
      }
    };
    setForm((form) => ({ ...form, ...data }));
  }, [formData, amountToBorrow]);

  const handleTermsChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleInputChange = (e) => {
    const [objectKey, key] = e.target.name.split(".");
    setForm((prev) => ({
      ...prev,
      [objectKey]: {
        ...prev[objectKey],
        [key]: e.target.value
      }
    }));
  };

  const handleDateChange = (name, value) => {
    const strValue = moment(value).format("yyyy-MM-DD");
    const [objectKey, key] = name.split(".");
    setForm((prev) => ({
      ...prev,
      [objectKey]: {
        ...prev[objectKey],
        [key]: strValue
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await apiService.applyNewLoan(form);
      onComplete(result.data);
    } catch (e) {
      enqueueSnackbar("Could not submit new loan application. Please try again later or contact support.", { variant: "error" });
      console.log("Could not submit new loan application", e);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoadingData) {
    return (<StepLoading />);
  }

  return (
    <Container maxWidth='md'>
      <Paper elevation={3} style={{
        padding: "2rem",
        marginTop: "2rem",
        marginBottom: "2rem"
      }}>
        <form onSubmit={handleSubmit} autoComplete='off'>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} mb={2}>
              <TextField
                required
                disabled={isLoading}
                inputProps={{ readOnly: true }}
                fullWidth
                label='Application amount'
                value={formatPriceNumber(form.loanInformation.amount)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>$</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h7'>Personal Information</Typography>
            </Grid>
            <Grid item xs={12} sm={4} style={{ display: "none" }}>
              <TextField
                required
                disabled={isLoading}
                fullWidth
                name='personalInformation.firstName'
                label='First Name'
                value={form.personalInformation.firstName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} style={{ display: "none" }}>
              <TextField
                required
                fullWidth
                disabled={isLoading}
                name='personalInformation.lastName'
                label='Last Name'
                value={form.personalInformation.lastName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} style={{ display: "none" }}>
              <TextField
                required
                fullWidth
                disabled={isLoading}
                name='personalInformation.ssn'
                label='SSN'
                type='text'
                inputProps={{ readOnly: true }}
                value={form.personalInformation.ssn}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                disabled={isLoading}
                name='personalInformation.email'
                label='Email'
                type='email'
                inputProps={{ readOnly: true }}
                value={form.personalInformation.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                disabled={isLoading}
                name='personalInformation.homePhone'
                label='Phone'
                value={form.personalInformation.homePhone}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} style={{ display: "none" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label='Date of birth'
                  value={form.personalInformation.dateOfBirth}
                  onChange={(newValue) => {
                    handleDateChange("personalInformation.dateOfBirth", newValue);
                  }}
                  renderInput={(params) => <TextField fullWidth
                                                      required {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                disabled={isLoading}
                name='personalInformation.address1'
                label='Address 1'
                value={form.personalInformation.address1}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                disabled={isLoading}
                name='personalInformation.address2'
                label='Address 2'
                value={form.personalInformation.address2}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                fullWidth
                required
                disabled={isLoading}
                name='personalInformation.city'
                label='City'
                value={form.personalInformation.city}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl required fullWidth>
                <InputLabel>State</InputLabel>
                <Select
                  name='personalInformation.state'
                  label='State'
                  defaultValue={""}
                  onChange={handleInputChange}
                  value={form.personalInformation.state}>
                  >
                  {states.map((option) => (
                    <MenuItem key={option.abbreviation}
                              value={option.abbreviation}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                required
                disabled={isLoading}
                name='personalInformation.zip'
                label='ZIP'
                value={form.personalInformation.zip}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} style={{ display: "none" }}>
              <Typography variant='h7'>Bank Information</Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: "none" }}>
              <TextField
                required
                disabled={isLoading}
                fullWidth
                name='bankInformation.bankName'
                label='Bank Name'
                value={form.bankInformation.bankName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: "none" }}>
              <TextField
                required
                disabled={isLoading}
                fullWidth
                name='bankInformation.abaNumber'
                label='ABA Number'
                value={form.bankInformation.abaNumber}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: "none" }}>
              <TextField
                required
                disabled={isLoading}
                fullWidth
                name='bankInformation.accountNumber'
                label='Account Number'
                value={form.bankInformation.accountNumber}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: "none" }}>
              <FormControl required fullWidth disabled={isLoading}>
                <InputLabel>Account Type</InputLabel>
                <Select
                  name='bankInformation.accountType'
                  label='Account Type'
                  value={form.bankInformation.accountType}
                  onChange={handleInputChange}
                >
                  <MenuItem value='Checking'>Checking</MenuItem>
                  <MenuItem value='Savings'>Saving</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h7'>Work Information</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl required fullWidth disabled={isLoading}>
                <InputLabel>Employment Type</InputLabel>
                <Select
                  name='workInformation.employmentType'
                  label='Employment Type'
                  value={form.workInformation.employmentType}
                  onChange={handleInputChange}
                >
                  <MenuItem value='FullTime'>Full-time</MenuItem>
                  <MenuItem value='PartTime'>Part-time</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                disabled={isLoading}
                name='workInformation.jobTitle'
                label='Job Title'
                value={form.workInformation.jobTitle}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                required
                fullWidth
                disabled={isLoading}
                name='workInformation.employerName'
                label='Employer Name'
                value={form.workInformation.employerName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: "none" }}>
              <FormControl required fullWidth disabled={isLoading}>
                <InputLabel>Paycheck Form</InputLabel>
                <Select
                  name='workInformation.payCheckForm'
                  label='Paycheck Form'
                  value={form.workInformation.payCheckForm}
                  onChange={handleInputChange}
                >
                  <MenuItem value='DirectDeposit'>Direct Deposit</MenuItem>
                  <MenuItem value='PaperCheck'>Paper Check</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: "none" }}>
              <FormControl required fullWidth disabled={isLoading}>
                <InputLabel>Paycheck Frequency</InputLabel>
                <Select
                  label='Paycheck Frequency'
                  name='workInformation.payFrequency'
                  value={form.workInformation.payFrequency}
                  onChange={handleInputChange}
                >
                  <MenuItem value='Weekly'>Weekly</MenuItem>
                  <MenuItem value='BiWeekly'>Bi-weekly</MenuItem>
                  <MenuItem value='Monthly'>Monthly</MenuItem>
                  <MenuItem value='SemiMonthly'>Twice-Monthly</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                required
                disabled={isLoading}
                name='workInformation.monthlyPayAmount'
                label='Monthly Gross Income'
                type='number'
                value={form.workInformation.monthlyPayAmount}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label='Last pay day'
                  value={form.workInformation.lastPayDate}
                  onChange={(newValue) => {
                    handleDateChange("workInformation.lastPayDate", newValue);
                  }}
                  renderInput={(params) => <TextField required
                                                      fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label='Next pay day'
                  value={form.workInformation.nextPayDate}
                  onChange={(newValue) => {
                    handleDateChange("workInformation.nextPayDate", newValue);
                  }}
                  renderInput={(params) => <TextField required
                                                      fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12}>
              <FormControl fullWidth style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
                           disabled={isLoading}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleTermsChange}
                      name='acceptTerms'
                      color='primary'
                    />
                  }
                  label={
                    <>
                      Accept{" "}
                      <Link to='/terms' target='_blank'
                            rel='noopener'>Terms & Conditions</Link>
                      {" "}and{" "}
                      <Link to='/privacy' target='_blank'
                            rel='noopener'> Privacy Policy</Link>
                    </>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color='inherit'
              disabled={isLoading}
              onClick={onBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <LoadingButton disabled={!checked}
                           loading={isLoading}
                           variant='contained' color='primary'
                           type='submit'>Submit</LoadingButton>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default ApplyNewLoanStep2;
