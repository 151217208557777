import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PageContainer from "../../shared/components/container/PageContainer";
import * as React from "react";
import { useEffect } from "react";
import ApplyNewLoanStep1 from "./ApplyNewLoanStep1";
import ApplyNewLoanStep2 from "./ApplyNewLoanStep2";
import ApplyNewLoanStep3 from "./ApplyNewLoanStep3";
import { connect, useDispatch } from "react-redux";
import { loadLoanData } from "../../actions/loanActions";
import { useLocation } from "react-router-dom";

const mapStateToProps = (state) => ({
  isLoadingData: state.loan.applicationData === null,
  data: state.loan.applicationData
});

const ApplyNewLoan = ({ isLoadingData, data }) => {

  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (!data) {
      dispatch(loadLoanData());
    }
  }, [dispatch, data]);

  const steps = ["Select amount", "Review and make changes", "Complete"];

  const [activeStep, setActiveStep] = React.useState(0);
  const [amount, setAmount] = React.useState(200.0);
  const [result, setResult] = React.useState(null);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onCompleteStep1 = (amount) => {
    setAmount(amount);
    handleNext();
  };

  const onCompleteStep2 = (result) => {
    setResult(result);
    handleNext();
  };

  return (
    <PageContainer title='Apply for Additional Funds'>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 0 &&
        <ApplyNewLoanStep1 amountToBorrow={amount}
                           onComplete={onCompleteStep1} />}
      {activeStep === 1 &&
        <ApplyNewLoanStep2 amountToBorrow={amount}
                           isLoadingData={isLoadingData}
                           formData={data}
                           onComplete={onCompleteStep2}
                           onBack={handleBack} />}
      {activeStep === 2 && <ApplyNewLoanStep3 result={result} />}
    </PageContainer>
  );
};

export default connect(mapStateToProps)(ApplyNewLoan);
