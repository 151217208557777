import apiService from "../services/apiService";
import { enqueueSnackbar } from "notistack";
import { analyticsTracker } from "../services/analytics";

export const login = (form) => async dispatch => {
  dispatch({ type: "LOGIN_START" });
  try {
    const session = await apiService.login(form);
    localStorage.setItem("authToken", session.data.sessionId);
    const account = await apiService.getProfile();
    analyticsTracker.setUserID(account.data.email);
    analyticsTracker.setMetadata("customerId", account.data.customerId);
    analyticsTracker.setMetadata("loanId", account.data.loanId);
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: account.data
    });
  } catch (error) {
    let errorMsg = "Unexpected error please try again later or contact support.";
    if (error.response.status === 404) {
      errorMsg = "Invalid Email or SSN";
    }
    if (error.response.status === 406) {
      errorMsg = "Captcha verification failed. Please try again.";
    }
    if (error.response.status === 408) {
      errorMsg = "Session expired. Please refresh the page.";
    }
    if (error.response.status === 428) {
      const leadId = error.response.data.leadId;
      window.location.href = `${process.env.REACT_APP_PENDING_URL}${leadId}`;
      return;
    }
    enqueueSnackbar(errorMsg, { variant: "error" });
    dispatch({ type: "LOGIN_ERROR" });
  }
};

export const loadAccount = () => async dispatch => {
  try {
    dispatch({ type: "LOGIN_START" });
    const account = await apiService.getProfile();
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: account.data
    });
  } catch (error) {
    console.log(`Could not load account`, error);
  }
};

export const logout = () => async dispatch => {
  try {
    await apiService.logout();
  } catch (error) {
    console.log(`Logout error`, error);
  } finally {
    localStorage.removeItem("authToken");
    dispatch({
      type: "LOGOUT"
    });
  }
};
